import validator from 'validator'
import { parse } from 'tldts'

export const truncateMiddle = (string: string) => {
  if (string.length <= 9) return string

  return `${string.slice(0, 7)}...${string.slice(-5)}`
}

export const formatUsername = (username: string) => {
  const name = username || ''

  return name !== '' ? `${name.startsWith('0x') ? 'Anonymous' : name}` : ''
}

export const isValidLink = (content: string) => {
  const isIcann = parse(content).isIcann

  return ((validator.isURL(content, { protocols: ['http', 'https'] }) && (content.length < 256) && isIcann) && !content.includes(' ')) || content.length === 0
}

export const convertToWebUrl = (url: string) => {
  if (isValidLink(url)) {
    return 'https://' + simplifyUrl(url)
  } else {
    return 'https://remx.xyz'
  }
}

const simplifyUrl = (url: string) => url.replace(/^(https?:\/\/)?(www\.)?/, '')

const convertAllUrls = (content: string, commonDelimiters: string[] = [], extraRegex = false) => {
  const getLink = (url: string) => {
    const result = simplifyUrl(url)

    return `<a href=https://${result} target='_blank' class='underline underline-offset-2 hover:no-underline whitespace-pre-wrap break-all'>${result}</a>`
  }

  let found = false
  commonDelimiters.forEach((commonDelimiter) => {
    if (content[content.length - 1] === commonDelimiter) {
      content = content.slice(0, -1)

      if (isValidLink(content)) {
        content = `${getLink(content)}${commonDelimiter}`
      } else {
        content = `${content}${commonDelimiter}`
      }

      found = true
    }
  })

  if (!found && extraRegex) {
    const regex = /\bhttps?:\/\/\S+\b/gi
    const matches = regex.exec(content)

    if (matches) {
      found = true

      const link = matches[0]
      const index = content.indexOf(link)
      content = content.slice(0, index) + getLink(link) + content.slice(index + link.length)
    }
  }

  if (!found) {
    if (isValidLink(content)) {
      content = `${getLink(content)}`
    }
  }

  return content
}

export const convertLinks = (content: string) => {
  const commonDelimiters = [',', '.', '!']

  const finalStr = content?.split(' ').map((substring) => {
    const newLineStr = substring.split('\n').map((substring) => {
      return convertAllUrls(substring, commonDelimiters, true)
    }).join('\n')

    return newLineStr
  }).join(' ')

  return finalStr || ''
}

export const removeCharactersFromString = (str, a, b) => {
  return str.substring(0, a) + str.substring(b + 1)
}

export const replaceCharactersInString = (str, pos, size, content) => {
  let tempStr = ''
  tempStr = removeCharactersFromString(str, pos, pos + size - 1)

  const formattedStr = tempStr.substring(0, pos) + content + tempStr.substring(pos, tempStr.length)

  return formattedStr
}

export const convertSlugs = (content: string) => {
  const slugs = findWordsWithAtSign(content)
  const positions = findCharPositions(content, '@')

  for (let i = slugs.length - 1; i >= 0; i--) {
    const slug = slugs[i]
    const pos = positions[i]
    const slugLength = slug.length + 1

    content = replaceCharactersInString(content, pos, slugLength, `<a href=https://${import.meta.env.VITE_BASE_DOMAIN_NAME}/${slug}/drops target='_self' class='font-medium hover:no-underline whitespace-pre-wrap break-all'>@${slug}</a>`)
  }

  return content
}

export const findWordsWithAtSign = (sentence) => {
  const regex = /@(\S+)/g

  const matches: string[] = []

  let match: any
  while ((match = regex.exec(sentence)) !== null) {
    if (match.length > 0) {
      matches.push(match[1])
    }
  }

  return matches
}

export const findCharPositions = (str, char) => {
  const positions: number[] = []
  for (let i = 0; i < str.length; i++) {
    if (str[i] === char) {
      positions.push(i)
    }
  }
  return positions
}

export const nearestPositionOfCharacter = (newSearchText, char, cursorPosition) => {
  const positionsOfAtSign = findCharPositions(newSearchText, char)

  let index = 0
  let found = false

  for (let i = positionsOfAtSign.length - 1; i > 0; i--) {
    if (positionsOfAtSign[i] <= cursorPosition && !found) {
      found = true
      index = i
    }
  }

  return positionsOfAtSign.length > 0 ? positionsOfAtSign[index] : 0
}

export const isConnectedToAtSign = (text, cursorPosition) => {
  const lastChar = text[cursorPosition - 1]

  for (let i = cursorPosition - 1; i >= 0; i--) {
    if (text[i] === ' ') {
      return false
    } else if (text[i] === '@') {
      return true
    }
  }
  return lastChar === '@'
}

export const nearestWordOfCharacter = (newSearchText, char, cursorPosition) => {
  let indexOfAtSign = 0
  const wordsWithChar = findWordsWithAtSign(newSearchText)

  const positionsOfAtSign = findCharPositions(newSearchText, char)
  for (let i = 0; i < positionsOfAtSign.length; i++) {
    if (positionsOfAtSign[i] <= cursorPosition) {
      indexOfAtSign = i
    }
  }

  return wordsWithChar.length > 0 ? wordsWithChar[indexOfAtSign] : ''
}

export const slugify = (string: string) => {
  return string
    .toLowerCase() // Convert the string to lowercase
    .replace(/\s+/g, '-') // Replace spaces with hyphens
    .replace(/[^\w-]+/g, '') // Remove non-word characters (except hyphens)
    .replace(/--+/g, '-') // Replace multiple consecutive hyphens with a single hyphen
    .replace(/^-+|-+$/g, '') // Remove leading and trailing hyphens
}

export const formatDate = (unformatedDate) => {
  const date = new Date(unformatedDate * 1000)
  const now = new Date()
  const diffInMilliseconds = now.getTime() - date.getTime()

  const diffInSeconds = Math.floor(diffInMilliseconds / 1000)
  const diffInMinutes = Math.floor(diffInSeconds / 60)
  const diffInHours = Math.floor(diffInMinutes / 60)
  const diffInDays = Math.floor(diffInHours / 24)

  if (diffInDays > 0) {
    return `${diffInDays} ${diffInDays > 1 ? 'days' : 'day'} ago`
  } else if (diffInHours > 0) {
    return `${diffInHours} ${diffInHours > 1 ? 'hrs' : 'hr'} ago`
  } else if (diffInMinutes > 0) {
    return `${diffInMinutes} ${diffInMinutes > 1 ? 'mins' : 'min'} ago`
  } else {
    return 'Just now'
  }
}

export const formatFutureDate = (unformattedDate: string, showFullTime = false) => {
  const futureDate = new Date(unformattedDate)
  const now = new Date()
  const diffInMilliseconds = futureDate.getTime() - now.getTime()
  if (diffInMilliseconds < 0) {
    return 'Done'
  }

  const diffInSeconds = Math.floor(diffInMilliseconds / 1000)
  const diffInMinutes = Math.floor(diffInSeconds / 60)
  const diffInHours = showFullTime ? Math.floor(diffInMinutes / 60) : Math.ceil(diffInMinutes / 60)
  const diffInDays = Math.floor(diffInHours / 24)

  if (showFullTime) {
    const hours = Math.floor(diffInMilliseconds / (1000 * 60 * 60))
    const minutes = Math.floor((diffInMilliseconds % (1000 * 60 * 60)) / (1000 * 60))
    const seconds = Math.floor((diffInMilliseconds % (1000 * 60)) / 1000)

    return `${diffInHours < 10 ? '0' : ''}${hours}h ${diffInMinutes % 60 < 10 ? '0' : ''}${minutes}m ${diffInSeconds % 60 < 10 ? '0' : ''}${seconds}s`
  } else {
    if (diffInDays > 0) {
      return `${diffInDays} ${diffInDays > 1 ? 'days' : 'day'}`
    } else if (diffInHours > 1) {
      return `${diffInHours} ${diffInHours > 1 ? 'hrs' : 'hr'}`
    } else if (diffInMinutes > 0) {
      return `${diffInMinutes} ${diffInMinutes > 1 ? 'mins' : 'min'}`
    } else {
      return 'Done'
    }
  }
}

export const replaceHttpWithHttps = (link: string) => {
  if (link.startsWith('http://')) {
    return link.replace('http://', 'https://')
  }
  return link // Return the original URL if it doesn't start with 'http://'
}
