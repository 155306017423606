const Feed = () => import('#pages/feed/feed.vue')

export default {
  path: '/feed',
  name: 'feed',
  component: Feed,
  meta: {
    title: 'Create. Collect. Earn.',
  },
}
