<template>
  <div class="flex-row md:hidden h-16 bottom-0 bg-black w-full fixed">
    <ul class="w-full flex-row grid grid-cols-5 py-4">
      <li>
        <MobileNavItem
          icon="HomeIcon"
          fill-icon-while-active
          :is-active="$route.name === 'home'"
          @click="navigateToAndCloseMenu('home')"
        />
      </li>

      <li v-if="isAuthenticated">
        <MobileNavItem
          icon="BarChart2Icon"
          :is-active="$route.name === 'leaderboard'"
          @click="navigateToAndCloseMenu('leaderboard')"
        />
      </li>

      <li v-if="isAuthenticated">
        <MobileNavItem
          icon="PlusCircleIcon"
          :is-active="$route.name === 'moment-create'"
          @click="handleCreateMoment"
        />
      </li>

      <li v-if="isAuthenticated">
        <MobileNavItem
          icon="DollarSignIcon"
          :is-active="$route.name === 'earnings'"
          @click="navigateToAndCloseMenu('me-earnings')"
        />
      </li>

      <li v-if="isAuthenticated">
        <MobileNavItem
          @click="navigateToAndCloseMenu('me-profile')"
        >
          <div
            class="w-8 h-8 rounded-full shadow flex items-center justify-center -translate-y-[2px] overflow-hidden border-0 bg-gray-100 transition-colors hover:border-white group-focus:border-white group-focus:hover:border-white"
            :class="$route.name === 'profile-moments'? 'border-2': 'border-0'"
          >
            <img
              v-if="icon"
              class="w-8 h-8 object-cover"
              :src="getImage(icon, { resize: { width: 100, fit: 'cover' } })"
            >
            <ProfileIcon
              v-else
              class="w-8 h-8"
            />
          </div>
        </MobileNavItem>
      </li>
    </ul>

    <CreateMomentUnauthorizedModal
      :is-open="isCreateMomentUnauthorizedModalOpen"
      @close="isCreateMomentUnauthorizedModalOpen = false"
    />
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import { useRouter } from 'vue-router'

import useAccount from '#composables/use-account'
import useLogin from '#composables/use-login'
import useImage from '#composables/use-image'

import MobileNavItem from '#components/home/mobile-nav-item.vue'
import ProfileIcon from '#components/icons/profile-icon.vue'
import CreateMomentUnauthorizedModal from '#components/global/create-moment-unauthorized-modal.vue'

const router = useRouter()
const { getImage } = useImage()
const { icon, isCreator, isAdmin } = useAccount()
const { account, isAuthenticated } = useLogin()

const profileMenuIsOpen = ref(false)
const isCreateMomentUnauthorizedModalOpen = ref(false)

const closeProfileMenu = () => profileMenuIsOpen.value = false

const navigateTo = (path: string) => {
  router.push({ name: path })
  profileMenuIsOpen.value = false
}

const navigateToAndCloseMenu = (path: string) => {
  navigateTo(path)
  closeProfileMenu()
}

const handleCreateMoment = () => {
  if (!isCreator.value && !isAdmin.value) {
    isCreateMomentUnauthorizedModalOpen.value = true
  } else {
    router.push({ name: 'moment-create', params: { profileSlug: account.value.slug || '' } })
  }
}
</script>
