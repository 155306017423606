<template>
  <Modal
    title="Invite only"
    :is-open="isOpen"
    :hide-action-buttons="true"
    @close="close"
  >
    <Body>
      Creating moments are by invitation only. To become eligible to create moments, you must receive an invite link from another artist. Please note that invite links are currently very limited.
    </Body>
  </Modal>
</template>

<script setup lang="ts">
import Body from '#components/typography/body'

import Modal from '#components/modal'

defineProps({
  isOpen: {
    type: Boolean,
    default: false,
  },
})

const emit = defineEmits(['close'])

const close = () => {
  emit('close')
}
</script>
