<template>
  <div
    :class="[bannerType.banner, iconAlignment, priority === 'tertiary' ? 'border border-brand-border' : '']"
    class="p-4 lg:px-5 rounded-lg flex space-x-2 w-full"
  >
    <div
      v-if="currentIcon"
      class="flex flex-col content-start items-start p-2 rounded-full bg-black self-start md:place-self-auto"
    >
      <component
        :is="currentIcon"
        :class="[isAnnouncement ? 'h-4 w-4' : 'h-6 w-6']"
      />
    </div>

    <div class="grow flex flex-col md:flex-row gap-2 md:gap-4 md:items-center pt-2 md:pt-0">
      <Body
        :black-text="!isAnnouncement && priority !== 'negative'"
        :white-text="isAnnouncement || priority === 'negative'"
        size="sm"
        :class="[isFullWidth && 'w-full', textCentered && 'text-center']"
        class="grow"
      >
        <slot />
      </Body>

      <div class="flex items-center justify-center md:justify-end space-x-4">
        <button
          v-if="secondaryAction.buttonName && secondaryAction.buttonAction"
          @click="secondaryAction.buttonAction"
        >
          <Body
            :black-text="!isAnnouncement"
            :white-text="isAnnouncement"
            inline
            size="sm"
            class="font-medium"
            :class="[isFullWidth && 'w-full']"
          >
            {{ secondaryAction.buttonName }}
          </Body>
        </button>

        <div v-if="action.buttonName && action.buttonAction">
          <GlobalButton
            size="sm"
            @click="action.buttonAction"
          >
            {{ action.buttonName }}
          </GlobalButton>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, type PropType } from 'vue'

import type { PriorityType, IconType, IconAlignType } from './types'

import CampaignIcon from '#components/icons/campaign-icon.vue'
import BulbIcon from '#components/icons/bulb-icon.vue'
import FarcasterIcon from '#components/icons/farcaster-icon.vue'
import XIcon from '#components/icons/x-platform-icon.vue'
import Body from '#components/typography/body'
import GlobalButton from '#components/global/global-button/global-button.vue'

const props = defineProps({
  iconType: {
    type: String as PropType<IconType>,
    default: null,
  },

  priority: {
    type: String as PropType<PriorityType>,
    default: 'primary',
  },

  action: {
    type: Object as PropType<{ buttonName: string, buttonAction: () => void }>,
    default: () => ({}),
  },

  secondaryAction: {
    type: Object as PropType<{ buttonName: string, buttonAction: () => void }>,
    default: () => ({}),
  },

  iconAlign: {
    type: String as PropType<IconAlignType>,
    default: 'center',
  },

  isFullWidth: {
    type: Boolean,
    default: false,
  },

  textCentered: {
    type: Boolean,
    default: false,
  },
})

const isXAnnouncement = computed(() => props.priority === 'announcement-x')
const isFarcasterAnnouncement = computed(() => props.priority === 'announcement-farcaster')
const isCampaignAnnouncement = computed(() => props.priority === 'announcement-campaign')
const isAnnouncement = computed(() => isXAnnouncement.value || isFarcasterAnnouncement.value || isCampaignAnnouncement.value)

const currentIcon = computed(() => {
  if (props.iconType === 'campaign') {
    return CampaignIcon
  } else if (props.iconType === 'bulb') {
    return BulbIcon
  } else if (props.iconType === 'farcaster' || isFarcasterAnnouncement.value) {
    return FarcasterIcon
  } else if (props.iconType === 'x-platform' || isXAnnouncement.value) {
    return XIcon
  } else {
    return null
  }
})

const bannerType = computed(() => {
  switch (props.priority) {
    default:
    case 'primary':
      return {
        banner: 'bg-[#D7E4F4]',
        actionButtonType: 'secondary-dark',
      }

    case 'secondary':
      return {
        banner: 'bg-brand-bg-section',
        actionButtonType: 'primary-dark',
      }

    case 'tertiary':
      return {
        banner: 'bg-white',
        actionButtonType: 'secondary-light',
      }

    case 'negative':
      return {
        banner: 'bg-brand-error',
        actionButtonType: 'secondary-light',
      }

    case 'announcement-farcaster':
      return {
        banner: 'bg-[#855DCD]',
        actionButtonType: 'secondary-light',
      }

    case 'announcement-x':
      return {
        banner: 'bg-[#1d9bf0]',
        actionButtonType: 'secondary-light',
      }

    case 'announcement-campaign':
      return {
        banner: 'bg-black',
        actionButtonType: 'secondary-light',
      }

    case 'warning':
      return {
        banner: 'bg-[#fcb8b3]',
        actionButtonType: 'secondary-light',
      }
  }
})

const iconAlignment = computed(() => {
  switch (props.iconAlign) {
    default:
    case 'top':
      return 'items-start'

    case 'center':
      return 'items-center'

    case 'bottom':
      return 'items-end'
  }
})
</script>
