import { gql } from '@apollo/client/core'

export const UPDATE_COLLECTION = gql`
  mutation UpdateCollection($input: UpdateCollectionInput!) {
    updateCollection(input: $input) {
      id,
      status,
      metadata,
    }
  }
`
